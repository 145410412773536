<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <el-table
      :data="state.tableData"
      :border="parentBorder"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div m="4">
            <p m="t-0 b-2">订单总数量: {{ props.row.orderCount || '暂无' }}</p>
            <p m="t-0 b-2">
              订单日期: {{ props.row.orderCount ? '如下' : '暂无' }}
            </p>
            <p m="t-0 b-2">
              订单总价格:
              {{
                (props.row.totalPrice
                  ? props.row.totalPrice.toFixed(2) + '元'
                  : '') || '暂无'
              }}
            </p>
            <p m="t-0 b-2">
              使用人数:
              {{
                (props.row.userCount ? props.row.userCount + '人' : '') ||
                '暂无'
              }}
            </p>
            <h3>订单数据</h3>
            <el-table :data="props.row.orderDatas" :border="childBorder">
              <el-table-column
                label="订单总数量"
                prop="orderCount"
                align="center"
              >
                <template #default="{ row }">
                  {{ row.orderCount || '暂无' }}
                </template>
              </el-table-column>
              <el-table-column label="订单日期" prop="orderTime" align="center">
                <template #default="{ row }">
                  {{ row.orderTime || '暂无' }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单总价格"
                prop="totalPrice"
                align="center"
              >
                <template #default="{ row }">
                  {{
                    (row.totalPrice ? row.totalPrice.toFixed(2) + '元' : '') ||
                    0 + '元'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="使用人数" prop="userCount" align="center">
                <template #default="{ row }">
                  {{ (row.userCount ? row.userCount + '人' : '') || 0 + '人' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单明细" prop="orderCustomData" align="center" />
      <el-table-column label="门票名称" prop="ticketName">
        <template #default="{ row }">
          {{ row.ticketName || '暂无' }}
        </template>
      </el-table-column>
      <el-table-column label="订单总数量" prop="orderCount" align="center">
        <template #default="{ row }">
          {{ row.orderCount || 0 }}
        </template>
      </el-table-column>
      <el-table-column label="订单总价格" prop="totalPrice" align="center">
        <template #default="{ row }">
          {{
            (row.totalPrice ? row.totalPrice.toFixed(2) + '元' : '') || 0 + '元'
          }}
        </template>
      </el-table-column>
      <el-table-column label="总人数" prop="userCount" align="center">
        <template #default="{ row }">
          {{ (row.userCount ? row.userCount + '人' : '') || 0 + '人' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default { name: 'OrderList' }
</script>
<script setup>
  import { getCurrentInstance, onActivated, reactive, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { Search } from '@element-plus/icons'
  import { exDataDetail } from '@/api/exhibitionManage'
  const router = useRouter()
  const route = useRoute()
  const parentBorder = ref(false)
  const childBorder = ref(false)
  const tableData1 = [
    {
      orderCount: 0,
      orderDatas: [
        {
          orderCount: 0,
          orderTime: '2016-05-03',
          totalPrice: 10,
          userCount: 10,
        },
        {
          orderCount: 0,
          orderTime: '2016-05-03',
          totalPrice: 10,
          userCount: 10,
        },
      ],
      ticketName: 'd1',
      totalPrice: 10,
      userCount: 10,
    },
  ]
  const state = reactive({
    meetingCode: '',
    tableData: [],
  })
  const getData = async () => {
    let resp = await exDataDetail(state.meetingCode)
    let data = resp.data.ticketData
    data.forEach((item) => {
      item.orderDatas = Array.isArray(item.orderDatas) ? item.orderDatas : []
      let arr = [...item.orderDatas]
      if (arr.length) {
        item.orderCustomData = item.orderDatas.length
      } else {
        item.orderCustomData = 0
      }
    })
    state.tableData = data
  }
  // 跳转订单详情
  const handleJumpOrderDetail = (row) => {
    router.push({
      path: '/exhibitionManage/orderDetail',
      query: {
        orderNo: row.orderNo,
      },
    })
  }

  onActivated(() => {
    state.meetingCode = route.query.meetingCode || ''
    state.meetingCode && getData()
  })
</script>
